import React, { Fragment, useEffect } from 'react'
import useAuth from '@hooks/useAuth'
import SEO from '@components/SEO'
import isBrowser from '@utils/isBrowser'

function Callback () {
  const { accessToken, redirectTo } = useAuth()

  useEffect(() => {
    if (isBrowser() && redirectTo) {
      const url = new URL(redirectTo)
      if (accessToken) url.searchParams.append('accessToken', accessToken)
      window?.location.replace(url.toString())
    }
  }, [])

  return (
    <Fragment>
      <SEO title='Redirecting' />
    </Fragment>
  )
}

export default Callback
